import React, { Component } from 'react';
import { setSnapshotFilterData } from "../../Redux/Actions/Snapshot";
import { connect } from "react-redux";
import { checkEmptyObject } from '../../Utils/ArrayFunction';
import { toast } from 'react-toastify';
import _lodash from "lodash";
import CurrencyInput from 'react-currency-input-field';
import Button from 'react-bootstrap/Button';

class SnapshotFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payment_details: [],
			categories: []
		}
	}
	handleOnChange = (e) => {
		const { name, value } = e.target;	
		let filter_data = {
			[name]: value
		}
		//.log(filter_data)
		this.props.dispatch(setSnapshotFilterData(filter_data));
	}
	handlePaymentOnChange = (e) => {
		let value = e.target.value.split("_");
		// let filter_data = {
		// 	"payment_method": {"method": value[0], "id": value[1] != 0 ? value[1] : null}
		// }

		this.props.dispatch(setSnapshotFilterData(filter_data));
	}
	applyFilter = () => { 
		let filter_data = {
			is_star: this.props.is_star,
			voucher_status: this.props.voucher_status,
			from_amount: this.props.from_amount,
			to_amount: this.props.to_amount,
			payment_status: this.props.payment_status,
			created_by: this.props.created_by,
			payment_method: this.props.payment_method,
			tags: this.props.tags,
			supplier_name: this.props.supplier_name,
			snapshot_number: this.props.snapshot_number,
			snapshot_type: this.props.snapshot_type,
			category_id: this.props.category_id,
			source: this.props.source,
			is_entity_mismatch: this.props.is_entity_mismatch,
			attachment_status: this.props.attachment_status,
			gst_status: this.props.gst_status,
			messages: this.props.messages,
			tds_status: this.props.tds_status
		}
		// let is_empty = checkEmptyObject(filter_data);
		let empty_filter_data = {
			is_star: "", from_amount: "", to_amount: "", category_id: "", snapshot_type: "", voucher_status: "",
			payment_status: "", created_by: "", payment_method: "0_0", tags: "", supplier_name: "", snapshot_number: "", source: "", attachment_status: "", gst_status: "", messages: "", tds_status: "", is_entity_mismatch: ""
		}
		let is_empty = _lodash.isEqual(filter_data, empty_filter_data);
		
		if (!is_empty) {
			this.setState({
				error: ""
			})
			this.props.applyFilter("apply");
			if (this.props.text) {
				this.props.reset();
				toast.error("Removed search filter and applied filter data")
			}
		} else {
			toast.error("Please specify filter criteria");
		}
	}
	
	resetFilter = () => {
		let filter_data = {
			is_star: "", category_id: "", customer_name: "", from_amount: "", to_amount: "", snapshot_type: "",
			payment_status: "", created_by: "", payment_method: "0_0", tags: "", supplier_name: "", snapshot_number: "",
			voucher_status: "", source: "", is_entity_mismatch: "", attachment_status: "", messages: "", tds_status: "", gst_status: ""
		}
		this.props.dispatch(setSnapshotFilterData(filter_data));
		this.props.applyFilter("reset");
	}
	amountChange = (name, value) => {
		if (value > 999999999.99) {
			return;
		}
		let filter_data = {
			[name]: value
		}
		this.props.dispatch(setSnapshotFilterData(filter_data));
	}
	
	render() {
		return (
			<div className="main-filter-popup-new">
				{/* <div className="row inner-row"> */}
				<div className="row">
					<div className="col-xl-1 col-lg-2 col-md-2 col-xs-12 pr-1">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.is_star}
								name="is_star" >
								<option selected value="Starred">Starred</option>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					<div className="col-xl-2 col-lg-2 col-md-2 col-xs-12">
						<select className="form-control"
							value={this.props.snapshot_type}
							onChange={this.handleOnChange}
							name="snapshot_type">
							<option value="">Type</option>
							{
								this.props.snapshot_types.map((type, i) => {
									return <option key={i} value={type.id}>{type.name}</option>;
								})
							}
							{/* <option value="6">Expense Invoice</option>
							<option value="5">E-way bill</option>
							<option value="2">Purchase Invoice</option>
							<option value="4">Purchase order</option>
							<option value="7">Purchase Return</option>
							<option value="1">Sales Invoice</option>
							<option value="3">Sales Return</option>
							<option value="8">Other</option> */}
						</select>
					</div>
					<div className="col-xl-3 col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<input type="text"
								className="form-control"
								placeholder="Company"
								value={this.props.supplier_name}
								onChange={this.handleOnChange}
								name="supplier_name" />
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.payment_method}
								name="payment_method">
								<option value="0_0">Payment Method</option>
								<option value={1 + "_" + 0}>Cash</option>
								{
									this.props.financial_institutes.map((bank, i) => {
										return <option key={i} value={2 + "_" + bank.id}>{bank.bank_name + " " + bank.account_number}</option>;
									})
								}
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-2 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.created_by}
								name="created_by" >
								<option value="">Created By</option>
								{
									this.props.creaters.map((created_by, index) => {
										return <option key={index} value={created_by.id}>{created_by.full_name}</option>
									})
								}
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								value={this.props.tags}
								name="tags"
								onChange={this.handleOnChange}>
								<option value="" selected>Tags</option>
								{
									this.props.listed_tags.map((tag, index) => {
										return <option key={index} value={tag.id}>{tag.name}</option>
									})
								}
							</select>
						</div>
					</div>
					{/* </div>
					<div className="row inner-row"> */}
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<div className="inner-date-sec">
								<input type="text"
									className="form-control"
									placeholder="ID"
									value={this.props.snapshot_number}
									onChange={this.handleOnChange}
									name="snapshot_number" />
							</div>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								value={this.props.category_id}
								onChange={this.handleOnChange}
								name="category_id">
								<option value="" selected>Categories</option>
								{
									this.props.categories.map((category, index) => {
										return <option key={index} value={category.id}>{category.name}</option>
									})
								}
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.payment_status}
								name="payment_status" >
								<option value="">Payment Status</option>
								<option value="1">Paid</option>
								<option value="2">Unpaid</option>
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.voucher_status}
								name="voucher_status" >
								<option value="">Voucher Status</option>
								<option value={2}>Sync Voucher Error</option>
								<option value={1}>Voucher Available</option>
								<option value={0}>Voucher Not Available</option>
							</select>
						</div>
					</div>
					<div className="col-lg-4 col-md-8 col-xs-12">
						<div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec">
							<div className="col-lg col-md col-xs-12">
								<div className="form-group date-sec">
									<div className="inner-date-sec">
										<CurrencyInput type="text"
											className="form-control text-right"
											placeholder="From Amount "
											decimalsLimit={2}
											prefix="&#8377; "
											value={this.props.from_amount}
											allowNegativeValue={false}
											onValueChange={(value, name) => this.amountChange(name, value)}
											name="from_amount" />
									</div>
								</div>
							</div>
							<div className="col-lg-1 col-md-1">
								<hr className='hr-seprator'/>
							</div>
							<div className="col-lg col-md col-xs-12">
								<div className="form-group date-sec">
									<div className="inner-date-sec">
										<CurrencyInput type="text"
											className="form-control text-right"
											placeholder="To Amount "
											decimalsLimit={2}
											prefix="&#8377; "
											value={this.props.to_amount}
											allowNegativeValue={false}
											onValueChange={(value, name) => this.amountChange(name, value)}
											name="to_amount" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-1 col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.source}
								name="source" >
								<option value="">Source</option>
								<option value={1}>Bulk</option>
								<option value={2}>Email</option>
								<option value={3}>Single</option>
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.messages}
								name="messages" >
								<option value="">Messages</option>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
								<option value={2}>Unread</option>
							</select>
						</div>
					</div>
					<div className="col-xl-1 col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.tds_status}
								name="tds_status" >
								<option value="">TDS</option>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					<div className="col-xl-1 col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.gst_status}
								name="gst_status" >
								<option value="">GST</option>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.attachment_status}
								name="attachment_status" >
								<option value="">Attachment</option>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-xs-12">
						<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.is_entity_mismatch}
								name="is_entity_mismatch">
								<option value="">Entity Mismatch</option>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					<div class="col-xl-3 col-lg-12 col-md-6 col-xs-12">
						<div className="reset_apply_btn_new">
							<Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
							<Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
						</div>
					</div>

					{ this.state.error ? 
						<div className="col-12">
							<p className="filter-error-msg">{this.state.error}</p>
						</div>
						:
						""
					}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Snapshot || {};
	const from_amount = all_value.from_amount || '';
	const to_amount = all_value.to_amount || '';
	const payment_status = all_value.payment_status || '';
	const created_by = all_value.created_by || '';
	const is_star = all_value.is_star || '';
	const voucher_status = all_value.voucher_status || '';
	const category_id = all_value.category_id || ''

	const snapshot_type = all_value.snapshot_type || '';
	const snapshot_number = all_value.snapshot_number || '';
	const supplier_name = all_value.supplier_name || '';
	const tags = all_value.tags || "";
	const payment_method = all_value.payment_method || '0_0';
	const source = all_value.source || '';

	const attachment_status = all_value.attachment_status || '';
	const is_entity_mismatch = all_value.is_entity_mismatch || '';
	const gst_status = all_value.gst_status || '';
	const messages = all_value.messages || '';
	const tds_status = all_value.tds_status || '';

	return {
		is_star, from_amount, to_amount, category_id, voucher_status,
		payment_status, created_by, payment_method, tags, supplier_name, snapshot_number, snapshot_type, source, attachment_status, gst_status, messages, is_entity_mismatch, tds_status
	};
}

export default connect(mapStateToProps)(SnapshotFilter);