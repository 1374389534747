import SessionManagement from "../Utils/SessionManagement";

class StorageData {

    getFinancialYear() {
        const financial_year = JSON.parse(localStorage.getItem('financial_year')) || {};
        if ((new Date()).getMonth() == financial_year.month && (new Date()).getDay() == financial_year.day) {
            return financial_year.financial_year;
        } else {
            return null;
        }
    }

    setFinancialYear(financial_year) {
        const financial_year_data = {
            financial_year: financial_year,
            month: (new Date()).getMonth(),
            day: (new Date()).getDay()
        }
        localStorage.setItem('financial_year', JSON.stringify(financial_year_data))
    }

    getDefaultFinancialYear() {
        const financial_years_arr = this.getFinancialYear();
        let default_yr = {
            year_type: 1, start_date: "", end_date: ""
        }
        if (financial_years_arr) {
            const year_type = localStorage.getItem('default_financial_year_type') || 1;
            const start_date = new Date(financial_years_arr.find(x => x.id == year_type).start_date);
            const end_date = new Date(financial_years_arr.find(x => x.id == year_type).end_date);
            default_yr = {
                year_type: year_type,
                start_date: start_date,
                end_date: end_date
            };
        }
        return default_yr;
    }

    setDefaultFinacialYear(year_type) {
        localStorage.setItem('default_financial_year_type', year_type)
    }

    getDefaultEntity() {
        const entity_detail = localStorage.getItem('entity');
        return !!entity_detail ? JSON.parse(entity_detail) : null;
    }

    setDefaultEntity(entity) {
        localStorage.setItem('entity', JSON.stringify(entity))
    }

    getQualifiedFileUrl(qualified_file_url) {
        if (qualified_file_url) {
            let authkey = SessionManagement.getAccessToken();
            return qualified_file_url + "&auth=" + authkey;
        }
    }

    setTransactionId(id) {
        this.deleteTaskManagerId();
        localStorage.setItem('money_link_transaction_id', id)
    }
    getTransactionId() {
        let id = localStorage.getItem('money_link_transaction_id') || null
        return id
    }
    deleteTransactionId() {
        localStorage.removeItem('money_link_transaction_id');
    }
    
    deleteAllStorageValue(){
        localStorage.clear();
    }

    deleteAllSessionStorageValue(){
        sessionStorage.clear();
    }

    setTaskManagerId(task_id){
        this.deleteTransactionId();
        localStorage.setItem('task_manager_task_id', task_id)
    }
    getTaskManagerId() {
        let id = localStorage.getItem('task_manager_task_id') || null
        return id
    }
    deleteTaskManagerId() {
        localStorage.removeItem('task_manager_task_id');
    }

    setFCMTOken(fcm_token){
        localStorage.setItem('fcm_token', fcm_token)
    }
    
    getFCMTOken(){
        return localStorage.getItem('fcm_token')
    }

    getDefaultFI() {
        const financial_institute = localStorage.getItem('financial_institute');
        return !!financial_institute ? JSON.parse(financial_institute) : null;
    }

    setDefaultFI(financial_institute) {
        localStorage.setItem('financial_institute', JSON.stringify(financial_institute))
    }

    setNotificationPermission(isallow){
        localStorage.setItem('allow_permission', isallow);
    }

    getNotificationPermision(){
        return localStorage.getItem('allow_permission');
    }

    getDefaultDashboardSelectedTab() {
        return localStorage.getItem('dashboard_tab');
    }

    setDefaultDashboardSelectedTab(tab) {
        localStorage.setItem('dashboard_tab', tab);
    }

    setDefaultSnapshotCards(default_cards) {
        localStorage.setItem('default_cards', JSON.stringify(default_cards));
    }

    getDefaultSnapshotCards(default_cards) {
        return JSON.parse(localStorage.getItem('default_cards'))
    }

    setDefaultMoneyLinkCards(default_cards) {
        localStorage.setItem('money_default_cards', JSON.stringify(default_cards));
    }

    getDefaultMoneyLinkCards(default_cards) {
        return JSON.parse(localStorage.getItem('money_default_cards'))
    }

    setMoneyLinkPageNo(no){
        localStorage.setItem('money_link_page_no', no);
    }

    getMoneyLinkPageNo() {
        return JSON.parse(localStorage.getItem('money_link_page_no'))
    }

    setSnapshotPageNo(no){
        localStorage.setItem('snapshot_page_no', no);
    }

    getSnapshotPageNo() {
        return JSON.parse(localStorage.getItem('snapshot_page_no'))
    }
}

export default new StorageData();
