import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import EntityService from '../../Services/ProfileManagement/EntityService';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DatePicker from "react-datepicker";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import AddMultipleEntity from './CA/AddMultipleEntity';
import SponserEntityComponent from './CA/SponserEntityComponent'
import SessionManagement from '../../Utils/SessionManagement';
import { toast } from 'react-toastify';
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid 

function dateFormatter(cell, row) {
  cell = new Date(cell);
  return `${('0' + cell.getDate()).slice(-2)}/${('0' + (cell.getMonth() + 1)).slice(-2)}/${cell.getFullYear()}`;
}
class EntityList extends Component {

  constructor(props) {
    super(props);
    this.ACCOUNT_TYPE = (SessionManagement.getUserDetail()).account_type;

    this.state = {
      name_filter: "",
      type_filter: "",
      license_filter: "",
      paid_by_filter: "",
      paid_by_filter: "",
      expire_filter: "",
      users: "",

      entityList: [],
      deleted_entities: [],
      hashtag_list: [],
      paid_by_me: 0,
      paid_by_others: 0,
      total: 0,
      show_filter_popup: false,
      show_multiple_entity: false,
      show_sponser_entity: false,

      filter_card: 1,

      // Intro js user guid
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".myacc_pfin",
          intro: "Account details",
          // position: 'right',
          // hideNext: true 
        },
        {
          element: ".pftabs_pfin",
          intro: "Tabs",
        },
        {
          element: ".ewidget_pfin",
          intro: "Widget",
        },
        {
          element: ".entitylist_pfin",
          intro: "Entity List"
        },
        // {
        //   element: ".addentity_pfin",
        //   intro: "Enter Details",
        // },  
        // {
        //   element: ".adduser_pfin",
        //   intro: "Enter Details",
        // },       
      ],
      // End Intro js user guid

    };
  }

  toggleFilterPopup = () => {
    this.setState({
      show_filter_popup: !this.state.show_filter_popup
    })
  };

  toggleMultipleEntity = (show_multiple_entity) => {
    this.setState({
      show_multiple_entity: show_multiple_entity
    })
  }

  toggleSponserEntity = (show_sponser_entity) => {
    this.setState({
      show_sponser_entity: show_sponser_entity
    })
  }

  getEntityList = () => {
    EntityService.getEntityList().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            entityList: data.result.entities,
            deleted_entities: data.result.deleted_entities,
            paid_by_me: data.result.paid_by_me,
            paid_by_others: data.result.paid_by_others,
            total: data.result.total,
            filter_data: data.result.filter,
            hashtag_list: data.result.filter.hashtags || [],
          });
        }
      });
  }

  componentDidMount() {
    // this.getEntityList();
  }

  handleOnChange = (e) => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  setDate = (date) => {
    this.setState({ expire_filter: date })
  }

  applyFilter = () => {
    if (
      !this.state.name_filter &&
      !this.state.type_filter &&
      !this.state.license_filter &&
      !this.state.paid_by_filter &&
      !this.state.users &&
      !this.state.hashtags &&
      !this.state.expire_filter
    ) {
      toast.error("Please specify a criteria")
    }
    this.refs.table.handleFilterData({
      name: !!this.state.name_filter ? this.state.name_filter : "",
      type: !!this.state.type_filter ? this.state.type_filter : "",
      plan_name: !!this.state.license_filter ? this.state.license_filter : "",
      paid_by: !!this.state.paid_by_filter ? this.state.paid_by_filter : "",
      users: !!this.state.users ? this.state.users : "",
      hashtags: !!this.state.hashtags ? this.state.hashtags : ""
    });
    if (!!this.state.expire_filter) {
      this.refs.end_date.applyFilter({
        date: this.state.expire_filter,
        comparator: '<='
      });
    }
  }

  goToEditView = (row) => {
    const { dispatch, history } = this.props;
    history.push({
      pathname: `/profile-management/entities/create/` + row.id
    });
  }
  getCaret = (direction) => {
    switch (direction) {
      case 'asc':
        return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
      case 'desc':
        return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
      default:
        return (<span className="icon-Group-4449 sort-both-icon"></span>);
    }
  }

  // displayEntities = (cell, row) => {
  //   // return <TagsList itemList={cell} list_length={20}/>
  //   let itemList = cell;
  //   let string_list = itemList.toString();
  //   if (itemList !== null && itemList.length !== 0) {      
  //     if(string_list.length >= 30){
  //       return <span>{string_list.substring(0, 30)} and 
  //       <OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled" className="tbl-user-ent">
  //         <ul style={{ listStyleType: 'none' }} >
  //           {itemList.map((item, index) => {
  //             return <li key={index}>{item}</li>
  //           })}
  //         </ul>
  //       </Tooltip>}>
  //           <a className="td-more-text s-sbold font-colr">
  //             <span> {`${itemList.length} more`} </span>
  //           </a>
  //       </OverlayTrigger>        
  //       </span>
  //     }else{
  //       return <span><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{string_list}</Tooltip>}>
  // 											<span>{string_list}</span>
  // 										</OverlayTrigger></span>
  //     }
  //   }
  // }

  displayEntities = (cell, row) => {
    // return <TagsList itemList={cell} list_length={20}/>
    let itemList = cell;
    let string_list = itemList.toString();
    if (itemList !== null && itemList.length !== 0) {
      if (string_list.length >= 30) {
        return <span>{string_list.substring(0, 30)} <span className='mr-1'>and</span>
          <OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled">
            <div className="p-0" style={{ listStyleType: 'none' }} >
              {itemList.join(', ')}
            </div>
          </Tooltip>}>
            <a className="td-view-all">{/* <span>{`${itemList.length} more`}</span> */}View All</a>
          </OverlayTrigger>
        </span>
      }
      else {
        return <span><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{string_list}</Tooltip>}>
          <span>{string_list}</span>
        </OverlayTrigger></span>
      }
    }
  }

  entitieName = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }

  displaytype = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }

  displayPaidby = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }

  resetFilter = () => {
    this.setState({
      name_filter: "", type_filter: "", license_filter: "", paid_by_filter: "",
      paid_by_filter: "", expire_filter: "", users: "", hashtags: ""
    }, () => {
      this.refs.table.handleFilterData({
        name: this.state.name_filter,
        type: this.state.type_filter,
        plan_name: this.state.license_filter,
        paid_by: this.state.paid_by_filter,
        users: this.state.users,
        hashtags: this.state.hashtags
      })
    })
  }

  rowStyleFormat = (row, rowIdx) => {
    if (!!row) {
      switch (row.status) {
        case 3: // no license
          return { backgroundColor: '#dbad4054' };
          break;
        case 4: // deleted
          return { backgroundColor: '#0000002b' };
          break
      }
    }
  }

  onCardSelected = (filter_card) => {
    this.setState({
      filter_card: filter_card
    });
    if (filter_card == 1) {
      this.refs.table.handleFilterData({
        paid_by_other: ""
      });
    } else if (filter_card == 2) {
      this.refs.table.handleFilterData({
        paid_by_me: 1
      });
    } else {
      this.refs.table.handleFilterData({
        paid_by_other: 1
      });
    }
  }

  // dataFormat={(cell) => !!cell ? cell : "Unpaid"}

  // displayPaidby = (cell, row) => {
  //   return	<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
  //       <span>{cell}</span>
  //   </OverlayTrigger>
  // } 

  // Intro js user guid   
  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  // End Intro js user guid

  render() {

    // Intro js user guid 
    const {
      stepsEnabled,
      steps,
      initialStep,
      hintsEnabled,
      hints
    } = this.state;
    // End Intro js user guid 

    const MANAGE_ENTITY_PERMISSION = this.props.account_permissions.includes(19);

    const entityList = [...this.props.entityList, ...this.props.deleted_entities]
    const options = {
      onRowClick: (row) => {
        this.goToEditView(row);
      }
    };

    let paid_by = [];

    if (this.props.filter_data)
      paid_by = _.sortBy(this.props.filter_data.paid_by, (data) => data.toLowerCase());

    return (
      <div>
        {/* Intro js user guid */}
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        />
        {/* End Intro js user guid */}

        {/* Widget */}
        <div className="row widget-filter-new sec-mb">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(1)}>
            <div className={"widget-block-new " + (this.state.filter_card == 1 ? " active-widget" : "")}>
              <p className="wtitle-new">Total</p>
              <p className="wcounter-new">{this.props.total}</p>
            </div>
          </div>
          {(!!this.props.paid_by_others) &&
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(2)}>
              <div className={"widget-block-new " + (this.state.filter_card == 2 ? " active-widget" : "")}>
                <p className="wtitle-new">Paid By Me</p>
                <p className="wcounter-new">{this.props.paid_by_me}</p>
              </div>
            </div>
          }
          {(!!this.props.paid_by_others) &&
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(3)}>
              <div className={"widget-block-new " + (this.state.filter_card == 3 ? " active-widget" : "")}>
                <p className="wtitle-new">Paid By Others</p>
                <p className="wcounter-new">{this.props.paid_by_others}</p>
              </div>
            </div>
          }
        </div>
        {/* End Widget */}

        {/* Filter */}
        <div className="row sec-mb">
          <div className="col-12">
            <div className="mainFilter-new d-flex justify-content-end">
              <Button variant={this.state.show_filter_popup ? " outline-secondary filter-btn-new border-radius-26 active-filter filter_snapin " : " outline-secondary filter-btn-new border-radius-26 filter_snapin"} onClick={this.toggleFilterPopup}>Filter <span className="icon-funnel gray-dark-icon filter-icon"></span></Button>
            </div>
          </div>

          <div className="col-12">
            {this.state.show_filter_popup &&
              <div className="main-filter-popup-new mt-2">
                <Form>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-xs-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Entity"
                          value={this.state.name_filter}
                          name="name_filter"
                          onChange={this.handleOnChange} />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-xs-12">
                      <div className="form-group">
                        <select className="form-control"
                          value={this.state.type_filter}
                          name="type_filter"
                          onChange={this.handleOnChange} >
                          <option value="">Type</option>
                          {this.props.filter_data ?
                            this.props.filter_data.type.sort().map((type, i) => {
                              return <option key={i} value={type}>{type}</option>
                            })
                            : null
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-xs-12">
                      <div className="form-group">
                        <select className="form-control"
                          value={this.state.license_filter}
                          name="license_filter"
                          onChange={this.handleOnChange}>
                          <option value="">License</option>
                          {this.props.filter_data ?
                            this.props.filter_data.plan_name.sort().map((name, i) => {
                              return <option key={i} value={name}>{name}</option>
                            })
                            : null
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-xs-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="User"
                          value={this.state.users}
                          name="users"
                          onChange={this.handleOnChange} />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-xs-12">
                      <div className="form-group">
                        <select className="form-control"
                          value={this.state.paid_by_filter}
                          name="paid_by_filter"
                          onChange={this.handleOnChange}>
                          <option value="">Paid by</option>
                          {this.props.filter_data ?
                            paid_by.map((name, i) => {
                              return <option key={i} value={name}>{name}</option>
                            })
                            : null
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-xs-12">
                      <div className="form-group date-sec">
                        <div className="inner-date-sec cursor-cmenu">
                          <DatePicker
                            selected={this.state.expire_filter}
                            placeholderText="Expires on or before"
                            onChange={date => this.setDate(date)}
                            className="form-control datepicker-icon"
                            autoComplete="off"
                            value={this.state.expire_filter}
                            name="expire_filter" />
                        </div>
                      </div>
                    </div>
                    <div className={"col-xl-3 col-lg-2 col-md-4 col-xs-12 " + (this.ACCOUNT_TYPE == 1 ? " d-none" : "")}>
                      <div className="form-group">
                        <select className="form-control"
                          value={this.state.hashtags}
                          name="hashtags"
                          onChange={this.handleOnChange}>
                          <option value="">Hashtags</option>
                          {
                            this.props.hashtag_list.map((name, i) => {
                              return <option key={i} value={name}>{name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-xl col-lg col-xs-12 text-right">
                      <div className="reset_apply_btn_new">
                        <Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
                        <Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            }
          </div>
        </div>
        {/* End Filter */}

        <div className="row main-table-listing listing-icons">
          <div className="col-12">
            <div className="table-sec">
              <BootstrapTable ref='table' data={entityList} responsive options={options} trStyle={this.rowStyleFormat}>
                <TableHeaderColumn width="29%" isKey dataField='name' dataSort={true} caretRender={this.getCaret} dataFormat={(cell, row) => this.entitieName(cell, row)}>Entity</TableHeaderColumn>

                {/* <TableHeaderColumn width="12%" dataField='type' dataFormat={(cell) => !!cell ? cell : "NA"} dataSort={true} caretRender={ this.getCaret }>Type</TableHeaderColumn> */}
                <TableHeaderColumn width="12%" dataField='type' dataFormat={(cell) => !!cell ? this.displaytype(cell) : "NA"} dataSort={true} caretRender={this.getCaret}>Type</TableHeaderColumn>

                <TableHeaderColumn width="9%" dataField='plan_name' dataFormat={(cell) => !!cell ? cell : "No License"} dataSort={true} caretRender={this.getCaret}>License</TableHeaderColumn>

                {/* <TableHeaderColumn dataField='paid_by' columnClassName={"pepaidby"} dataFormat={(cell) => !!cell ? cell : "Unpaid"} dataSort caretRender={ this.getCaret }>Paid By</TableHeaderColumn> */}

                <TableHeaderColumn width="11%" dataField='paid_by' columnClassName={"pepaidby"} dataSort caretRender={this.getCaret} dataFormat={(cell, row) => !!cell ? this.displayPaidby(cell, row) : "Unpaid"}>Paid By</TableHeaderColumn>

                <TableHeaderColumn dataField='users' dataFormat={(cell, row) => this.displayEntities(cell, row)}>Users</TableHeaderColumn>

                {/* <TableHeaderColumn dataField='paid_by' columnClassName={"pepaidby"} dataFormat={(cell) => !!cell ? cell : "Unpaid"} dataSort caretRender={ this.getCaret } columnTitle>Paid By </TableHeaderColumn> <TableHeaderColumn dataField='users' dataFormat={(cell, row) => this.displayEntities(cell, row)}  columnTitle={(cell, row) => "\n\n\n\n"+cell.toString()} >Users </TableHeaderColumn> */}

                {
                  <TableHeaderColumn dataField='hashtags' dataFormat={(cell, row) => !!cell ? cell.toString() : ""} hidden>hashtags</TableHeaderColumn>
                }
                <TableHeaderColumn width="140px" tdStyle={{ textAlign: 'center' }} thStyle={{ textAlign: 'center' }} ref='end_date' dataField='end_date' dataFormat={(cell) => !!cell ? cell : "NA"} filter={{ type: 'DateFilter' }} dataSort={true} caretRender={this.getCaret}>Expires on</TableHeaderColumn>

              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const all_value = state.Session || {};
  const module_permissions = all_value.module_permissions || [];
  const account_permissions = all_value.account_permissions || [];
  const entity_list = all_value.entity_list || [];
  const user_detail = all_value.user_detail || {}
  return {
    module_permissions, account_permissions, entity_list, user_detail
  };
}

export default connect(mapStateToProps)(withRouter(EntityList));
