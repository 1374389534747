import React, { useState } from 'react'
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import logo_tally from '../../Assets/img/logo-tally.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import gplay from '../../Assets/img/gplay.png';
import appstore from '../../Assets/img/appstore.png';
import googleplay from '../../Assets/img/googleplay.png';
import accountp from '../../Assets/img/accountp.png';
import downloadicon from '../../Assets/img/downloadicon.svg';
import { Dropdown } from 'react-bootstrap';
import { logout } from "../../Redux/Actions/Auth";
import SetEntity from '../../Components/BoilerPlate/SetEntityModal';
import SessionManagement from '../../Utils/SessionManagement';
import StorageData from '../../Helpers/StorageData';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import Button from 'react-bootstrap/Button';

import pdficont from '../../Assets/img/pdficont.png';
import testimg from '../../Assets/img/profile.png';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Notification from './Notification';
import { toast } from 'react-toastify';
import MasterService from '../../Services/MasterService';

// import "../../Assets/ModulScss/Styles.scss";

// In the notifications section there were some spana tags instead of span tags I changed them

const TheHeaderNew = (props) => {
  const user_detail = props.user_detail;
  const default_entity_detail = StorageData.getDefaultEntity() || {};
  var entity_list = props.entity_list;
  const [showEntityModal, setEntityModal] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const history = useHistory();

  
  function logoutFun() {
    // dispatch(logout())
    // SessionManagement.RemoveAll();
    // StorageData.deleteAllStorageValue();
    history.push("/logout");
    // window.location.reload();
  }

  function setDefaultEntity(entity) {
    StorageData.setDefaultEntity(entity);
    StorageData.setDefaultFI(null);
    history.push("/set-default-entity");
    // window.location.reload();
  }

  function toggleSetEntity() {
    setEntityModal(!showEntityModal)
  }

  function firstLetter(entity) {
    if (entity) {
      entity.trim();
      return entity.charAt(0)
    }
  }

  function filterEntity(e) {
    let search = e.target.value;
    setSearch(search);
  }

  function submitEntityHandler(e) {
    e.preventDefault()
    if (!search) {
      toast.error("Please specify search criteria");
    }
  }
  console.log(props.default_entity_detail)

  function downloadTallyBridge() {
    MasterService.downloadTallyBridge().then((data) => {
      //console.log(data)
      const link = document.createElement('a');
      link.href = StorageData.getQualifiedFileUrl(data.result.setup_url);
      link.setAttribute(
        'download',
        `Buktec_Release.zip`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }

  return (
    <>
      {showEntityModal && <SetEntity toggleSetEntity={toggleSetEntity} />}
      {/* <nav className="navbar navbar-expand-lg navbar-light gray-bg fixed-top" id="mainNav"> */}
      <nav className="navbar navbar-expand-lg navbar-light gray-bg sticky-top" id="mainNav">
        <div className="row no-gutters w-100">
          <div className="col-xl-2 col-lg-2 col-md-2 d-flex align-items-center">
            {/* <Link to="/home">
              <img src={logo_dash} className="img-fluid d-none d-sm-block" />
              <img src={logo_fevi} className="img-fluid d-block d-sm-none" />
            </Link> */}

            {Object.keys(props.default_entity_detail).length != 0 ?
              <Link to="/home"><img src={logo_dash} className="main-logo-new img-fluid d-none d-sm-block" /></Link>
              :
              <Link to="/profile-management"><img src={logo_dash} className="main-logo-new img-fluid d-none d-sm-block" /></Link>
            }
            <Link>
              <img src={logo_fevi} className="img-fluid d-block d-sm-none" />
            </Link>

            {/* <div className="col-xl-2 col-lg col-md-2 d-flex align-items-center">
              {Object.keys(props.default_entity_detail).length != 0 ?
                <Link className="d-none d-sm-block" to="/home"><img className="main-logo-new" src={logo_dash} /></Link>
                :
                <Link className="d-none d-sm-block" to="/profile-management"><img className="main-logo-new" src={logo_dash} /></Link>
              }
              <a className="d-block d-sm-none" href="#"><img className="mob-logo" src={logo_fevi} /></a>
            </div> */}
          </div>

          <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-center">
            <h1 className="m-0 company-name-new d-flex align-items-baseline justify-content-center w-100">
              <span className="ellipsisc ellipsisc-line-1 cname-new">{props.default_entity_detail.name}</span>
              {/* <i className='license ml-1 red-text'>License Expired</i> */}
              {/* <i className='license ml-1'>(Superb)</i> */}
              <i className='license-new ml-1'>
                {!!props.default_entity_detail.plan_name && <span> {props.default_entity_detail.status == 2 ? <span className="red-text">License Expired</span> : " (" + props.default_entity_detail.plan_name + ")"}</span>}
              </i>
            </h1>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-2 pr-0 header-dropdown-new d-flex align-items-center justify-content-end ">
            <ul className="d-flex align-items-center mb-0">
              {/* Gift Dropdown */}
              {/* <li>
                  <Dropdown>                                
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                          <span className="icon-gift1 gray-icon"></span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="h-dropdown-sec-new">
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
              </li> 
              <li className="main-gift-sec d-none">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      <a href="#"><span className="icon-gift1 gray-icon"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="h-dropdown-sec-new d-none">
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              */}

              {/* <li>
                <a href="#">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Download Buktec Software Bridge for Tally</Tooltip>}>
                    <img onClick={downloadTallyBridge} className="logo_tally" src={logo_tally} />
                  </OverlayTrigger>
                </a>
              </li> */}

              <li>
                {/* <a href="#">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Download Buktec Software Bridge for Tally</Tooltip>}>
                  <img onClick={downloadTallyBridge} className="logo_tally" src={logo_tally} />
                  </OverlayTrigger>
                </a> */}
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Tally Bridge Installer</Tooltip>}>
                      <a class="m-1 dwnldh-icon" target="_blank" onClick={downloadTallyBridge}>
                        <span class="icon-download1 gray-icon"></span>
                      </a>
                    </OverlayTrigger>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>

              {/* Help */}
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow pl-2" id="dropdown-basic">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Help</Tooltip>}>
                      <a href="https://help.thebuktec.com/" class="m-1 text-decoration-none" id="dropdown-basic.btn" target="_blank">
                        <span className="gray-icon"><span class="icon-Help"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span></span>
                      </a>
                    </OverlayTrigger>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>

              {/* Bell Dropdown Notification */}
              <li className="notification">
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <div className="position-relative">
                      <span className="icon-bell gray-icon"></span>
                      {
                        !!props.notification_count &&
                        <span className="bell-notifi-count">{props.notification_count}</span>
                      }
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="h-dropdown-sec-new noti-drp-width p-3">
                    <Notification />
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              {/* User Dropdown */}
              <li className="main-h-profile">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className='user-drp d-flex align-items-center justify-content-center'>
                    {
                      !!user_detail.qualified_url ?
                        <img className="profileh-img" src={StorageData.getQualifiedFileUrl(user_detail.qualified_url)} />
                        :
                        <span className="profileh-name">{user_detail.full_name.charAt(0)}</span>
                    }
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="h-dropdown-sec-new gray-bg">
                    <div className="user-h-detail">
                      <div className="d-flex justify-content-between">
                        <div className="mr-3">
                          {
                            !!user_detail.qualified_url ?
                              <img className="profileh-drp-img" src={StorageData.getQualifiedFileUrl(user_detail.qualified_url)} /> :
                              <div className="profileh-drp-name cap d-flex align-items-center justify-content-center">
                                {user_detail.full_name.charAt(0)}
                              </div>
                          }
                        </div>
                        <div className="blue-text mr-auto" style={{ marginTop: '-3px' }}>
                          <span className="d-block size-xx">{user_detail.full_name.length >= 25 ? `${user_detail.full_name.substring(0, 25)}...` : user_detail.full_name}</span>
                          <span className="d-block size-xvi">{user_detail.email}</span>
                          <span className="d-block size-xv">{user_detail.phone_number}</span>
                        </div>
                        <Link to="/my-profile" className="ml-3">
                          <span className="icon-view-details size-xxviii blue-icon"></span>
                        </Link>
                      </div>
                      <div className="text-right">
                        <Button variant="success" className="h-green-btn" onClick={logoutFun}>Logout</Button>
                      </div>
                    </div>

                    {/* Entities Search Box */}
                    {
                      (entity_list.length > 10) &&
                      <form onSubmit={submitEntityHandler} className="search-sec mt-2 d-flex align-items-center">
                        <input type="text" onChange={filterEntity} placeholder="Search Entity" />
                        <button onClick={submitEntityHandler} type="button"><span className="icon-loupe"></span></button>
                      </form>
                    }

                    {/* Entities List */}
                    <div className="h-profile-entities-list mt-2">
                      <p className="entities-header s-sbold mb-0">Entities</p>
                      <ul className="p-0 h-ul-entities-list white-bg">
                        {
                          entity_list.map((entity, index) => {
                            if (!entity.name.toLowerCase().includes(search.toLowerCase()))
                              return true;
                            return <li key={index} onClick={() => { entity.status == 1 ? "" : setDefaultEntity(entity) }} className={"d-flex align-items-center justify-content-between cpointer " + (entity.status == 1 ? " de-active " : " ") + (default_entity_detail.id == entity.id ? " active " : " ")}>
                              <div className="flex-3 d-flex align-items-center justify-content-center">
                                {
                                  entity.entity_logo ?
                                    <img className="drp-entity-letter-img" src={StorageData.getQualifiedFileUrl(entity.entity_logo)} />
                                    :
                                    <span className="drp-entity-letter cap s-sbold d-flex align-items-center justify-content-center">{firstLetter(entity.name)}</span>
                                }
                              </div>
                              <div className="flex-6 drp-entity-name s-sbold blue-text ellipsisc ellipsisc-line-1">
                                {entity.name}
                              </div>
                              <div className="flex-3 d-flex align-items-center justify-content-end">
                                {
                                  (entity.status != 1 && default_entity_detail.id != entity.id) &&
                                  <span className="drp-entity-count d-flex align-items-center justify-content-center">{entity.count || 0}</span>
                                }
                              </div>
                            </li>
                          })
                        }
                      </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown >
              </li >

              {/* Hamburger Menu Dropdown */}
              < li className='hamburger-menu' >
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="h-icons">
                    <span className="icon-menu"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="h-dropdown-sec-new">
                    {/* Left Menu */}
                    {/* <div className="d-flex menu-gap padding-menu"> */}
                    <div className="d-flex menu-gap padding-menu">
                      <div className={"menugap" + (entity_list.length != 0 ? "" : " d-none")}>
                        {!!props.module_permissions.length && <h6 className="menu-title">Main</h6>}
                        {/* <div className={"col-lg-6 col-md-6" + (entity_list.length != 0 ? "" : " d-none")}>
                        {!!props.module_permissions.length && <div className="menu-title"><span className="title-name">Main</span></div>} */}

                        <ul className="p-0 mb-2 menu-list">
                          {!!props.module_permissions.length &&
                            <li>
                              <Dropdown.Item href="/home" className="menu-link">
                                <span className="icon-Group-2453 h-menu-icon iconsc"></span><span className="menu-name">Home</span>
                              </Dropdown.Item>
                            </li>
                          }
                          {!!props.module_permissions.length &&
                            <li>
                              <Dropdown.Item href="/dashboard" className="menu-link">
                                <span className="icon-Dashboard h-menu-icon iconsc"><span className="path1"></span><span className="path2"></span></span><span className="menu-name">Dashboard</span>
                              </Dropdown.Item>
                            </li>
                          }
                        </ul >

                        <h6 className={"menu-title " + (props.module_permissions.includes(4) ? "" : "d-none")}>Master Data</h6>
                        <ul className="p-0 mb-2 menu-list">
                          <li className={props.module_permissions.includes(4) ? "" : "d-none"}>
                            <Dropdown.Item href="/financial-institutes" className="menu-link">
                              <span className="icon-FI h-menu-icon iconsc"><span className="path1"></span><span className="path2"></span></span> <span className="menu-name">Banking</span>
                              {props.bank_gap_count_val != 0 && props.bank_gap_count_val?
                              // <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.bank_gap_count_val} - Gap{props.bank_gap_count_val > 1?'s':''} found in the statement:<span className="gap-count-date">&nbsp;31-02-24</span><span className="gap-count-date">, 26-03-24</span></Tooltip>}>
                                <span className="menu-gap-count">{props.bank_gap_count_val}</span>
                              // </OverlayTrigger>
                              :null}
                            </Dropdown.Item>
                          </li>
                        </ul>

                        <h6 className={"menu-title " + ((props.module_permissions.includes(1) || props.module_permissions.includes(3) || props.module_permissions.includes(3)) ? "" : "d-none")}>Capture Evidence</h6>
                        <ul className="p-0 mb-2 menu-list">
                          <li className={props.module_permissions.includes(1) ? "" : "d-none"}>
                            <Dropdown.Item href="/snapshot/evidence" className="menu-link">
                              <span className="icon-Evidence h-menu-icon iconsc"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="menu-name">Snapshot</span>
                            </Dropdown.Item>
                          </li>
                          <li className={props.module_permissions.includes(2) ? "d-none" : "d-none"}>
                            <Dropdown.Item href="/invoices" className="menu-link">
                              <span className="icon-bill h-menu-icon iconsc"></span> <span className="menu-name">Invoices</span>
                            </Dropdown.Item>
                          </li>
                          <li className={props.module_permissions.includes(3) ? "d-none" : "d-none"}>
                            <Dropdown.Item href="/cheque" className="menu-link">
                              <span className="icon-cheque-2 h-menu-icon iconsc"></span> <span className="menu-name">Cheques</span>
                            </Dropdown.Item>
                          </li>
                        </ul>

                        <h6 className={"menu-title " + (props.module_permissions.includes(5) ? "" : "d-none")}>Link Evidence</h6>
                        <ul className="p-0 menu-list">
                          <li className={props.module_permissions.includes(5) ? "mb-0" : "d-none"}>
                            <Dropdown.Item href="/money-link" className="menu-link">
                              <span className="icon-Link-icon h-menu-icon iconsc"></span> <span className="menu-name">Money Link</span>
                              
                              {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">2 - Gap found in the statement:<span className="gap-count-date">&nbsp;31-02-24</span><span className="gap-count-date">, 26-03-24</span></Tooltip>}>
                                <span className="menu-gap-count">2</span>
                              </OverlayTrigger> */}
                            </Dropdown.Item>
                          </li>
                        </ul>
                      </div >

                      {/* Right Menu*/}
                      < div className="menugap" >
                        <h6 className="menu-title">Tools</h6>
                        <ul className="p-0 mb-2 menu-list">
                          {!!props.module_permissions.length &&
                            <li>
                              <Dropdown.Item href="/task-manager" className="menu-link">
                                <span className="icon-Task-manager h-menu-icon iconsc"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="menu-name">Task Manager</span>
                              </Dropdown.Item>
                            </li>
                          }
                          <li className={props.module_permissions.includes(10) ? "" : "d-none"}>
                            <Dropdown.Item href="/reports" className="menu-link">
                              <span className="icon-Exports h-menu-icon iconsc"><span className="path1"></span><span className="path2"></span></span> <span className="menu-name">Reports</span>
                            </Dropdown.Item>
                          </li>
                          <li className={!!props.account_permissions.length ? "" : "d-none"}>
                            <Dropdown.Item href="/profile-management/entities" className="menu-link">
                              <span className="icon-Profile-management h-menu-icon iconsc"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="menu-name">Profile Management</span>
                            </Dropdown.Item>
                          </li>

                          <li className={props.module_permissions.includes(7) ? "" : "d-none"}>
                            <Dropdown.Item href="/docbox" className="menu-link">
                              <span class="icon-Doc-box h-menu-icon iconsc"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Docbox</span>
                            </Dropdown.Item>
                          </li>
                        </ul>

                        {/* AC Partner */}
                        {
                          (user_detail.account_type == 1 && !!props.default_entity_detail.account_name) &&
                          <div className="text-center mb-3">
                            <p className="blue-text s-sbold mb-2">Accounting Partner</p>
                            {!!props.default_entity_detail.account_logo ? <img className="img-fluid ac-partner-img" src={StorageData.getQualifiedFileUrl(props.default_entity_detail.account_logo)} /> :
                              <p className="ac-partner-h-name mb-0">{firstLetter(props.default_entity_detail.account_name)}</p>
                            }
                            <p className="blue-text s-sbold mt-1 mb-0">{props.default_entity_detail.account_name}</p>
                          </div>
                        }

                        {/* App Logos */}
                        {/* <div className="d-flex mt-2">
                          <a href="https://play.google.com/store/apps/details?id=com.buktec&pli=1" target="_blank" className="s-sbold font-colr">
                            <img className="img-fluid applogo mr-3" src={googleplay} />
                          </a>
                          <a href="https://apps.apple.com/in/app/buktec/id1603235288" target="_blank" className="s-sbold font-colr">
                            <img className="img-fluid applogo" src={appstore} />
                          </a>
                        </div> */}
                        {/* End App Logos */}
                      </div >
                    </div >
                  </Dropdown.Menu >
                </Dropdown >
              </li >
              {/* End Hamburger Menu Dropdown */}
            </ul >
            {/* End ul li */}
          </div >
        </div >
      </nav >
    </>
  )
}


function mapStateToProps(state) {
  const all_value = state.Session || {};
  const banking_values = state.Banking || {};
  const notification__value = state.Notification || {};
  const entity_list = all_value.entity_list || [];
  const account_permissions = all_value.account_permissions || [];
  const module_permissions = all_value.module_permissions || [];
  const user_detail = all_value.user_detail || {}
  const notification_count = notification__value.count || 0;
  const default_entity_detail = all_value.default_entity_detail || {}
  const bank_gap_count_val = banking_values.bank_gap_count || null;
  return {
    bank_gap_count_val,entity_list, account_permissions, module_permissions, user_detail, notification_count, default_entity_detail
  };
}
export default connect(mapStateToProps)(withRouter(TheHeaderNew));