import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import logo from '../../Assets/img/logo.png';
import Timer from '../../Components/Timer';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { register } from "../../Redux/Actions/Auth";
import { toast } from 'react-toastify';
import AuthService from "../../Services/AuthService";

import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../Utils/Config';

const required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Password is required</small>
    );
  }
};
const emailAccountCode = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Account Code / Account Email ID is required</small>
    );
  }
  let isnum = /^\d+$/.test(value);
  if (!isEmail(value) && !isnum) {
    return (
      <small className="form-error-text">Please enter valid Email ID</small>
    );
  } else if (value.length != 8 && isnum) {
    return (
      <small className="form-error-text">Please enter valid Account Code</small>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <small className="form-error-text">The password must be between 6 and 40 characters.</small>
    );
  }
};

const vusername = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Username is required</small>
    );
  } else if (value.length < 6 || value.length > 20) {
    return (
      <small className="form-error-text">The username must be between 6 and 20 characters.</small>
    );
  }
};

const re = /^[0-9\b]+$/;
class Register extends Component {
  constructor(props) {
    super(props);
    this.handleForgetPwd = this.handleForgetPwd.bind(this);
    this.handleResetPwd = this.handleResetPwd.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeFirstinp = this.onChangeFirstinp.bind(this);
    this.onChangeSecondinp = this.onChangeSecondinp.bind(this);
    this.onChangeThirdinp = this.onChangeThirdinp.bind(this);
    this.onChangeFourthinp = this.onChangeFourthinp.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onPasteHandler = this.onPasteHandler.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      reason: {},

      fpPage: true,
      otpPage: false,
      newPwdPage: false,
      completePage: false,
      firstinp: "",
      secondinp: "",
      thirdinp: "",
      fourthinp: "",
      invalidOTP: false,
      resetTimerTime: 30,
      resetTimer: false,
      progress: 1,
      timer: 5,

      incorrect_email: false,
      incorrect_uname: false,
      disabled_resend_otp: true
    };
  }

  componentDidMount() {
    loadReCaptcha(RECAPTCHA_SITE_KEY);
  }


  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
    // red error on input field
    if (vusername(e.target.value) === undefined) {
      this.setState({
        incorrect_uname: false,
      });
    } else {
      this.setState({
        incorrect_uname: true,
      });
    }
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
    // red error on input field
    if (emailAccountCode(e.target.value) === undefined) {
      this.setState({
        incorrect_email: false,
      });
    } else {
      this.setState({
        incorrect_email: true,
      });
    }
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  onPasteHandler(e) {
    e.preventDefault();
    let num = e.clipboardData.getData('text/plain').trim()
    console.log(num)
    this.setState({
      firstinp: num.charAt(0),
      secondinp: num.charAt(1),
      thirdinp: num.charAt(2),
      fourthinp: num.charAt(3)
    })
  }

  onChangeFirstinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        firstinp: ""
      });
    } else {
      this.setState({
        firstinp: e.target.value,
      }, (firstinp) => { this.secondinp.focus(); });
    }
  }
  onChangeSecondinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        secondinp: ""
      }, () => { this.firstinp.focus(); });
    } else {
      this.setState({
        secondinp: e.target.value,
      }, () => { this.thirdinp.focus(); });
    }
  }
  onChangeThirdinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        thirdinp: ""
      }, () => { this.secondinp.focus(); });
    } else {
      this.setState({
        thirdinp: e.target.value,
      }, () => { this.fourthinp.focus(); });
    }
  }
  onChangeFourthinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        fourthinp: ""
      }, () => { this.thirdinp.focus(); });
    } else {
      this.setState({
        fourthinp: e.target.value,
      });
    }
  }

  handleForgetPwd(e) {
    e.preventDefault();

    this.form.validateAll();

    if (this.state.email == "") {
      this.setState({
        incorrect_email: true,
      });
    }
    if (this.state.username == "") {
      this.setState({
        incorrect_uname: true,
      });
    }
    if (this.state.password == "") {
      this.setState({
        is_password: true,
      });
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({
        loading: true,
      });
      // you will get a new token in verifyCallback
      this.recaptcha.execute();
    }
  }


  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({
      recaptchaToken: recaptchaToken,
    });
    if (this.state.loading) {
      this.callForgetPassAPI();
    }

  }

  callForgetPassAPI = () => {
    const { dispatch, history } = this.props;
    if (this.checkBtn.context._errors.length === 0) {
      AuthService.forgotPassword(this.state.email, this.state.username, this.state.recaptchaToken).then(
        (data) => {
          if (data.response_code == 200) {
            this.setState({
              progress: 2,
              register_id: data.result.id,
              loading: false,
              fpPage: false,
              otpPage: true,
              resetTimerTime: data.result.timer / 60,
              resetTimer: true,
              email: data.result.send_on
            });
            setTimeout(() => { this.setState({ disabled_resend_otp: false }) }, data.result.resend_after * 1000);
            // toast.success(data.message);
          } else if (data.response_code == 429) {
            toast.error(data.message);
            this.setState({
              loading: false
            });
          } else {
            toast.error(data.message);
            this.setState({
              loading: false
            });
          }
        });

    } else {
      this.setState({
        loading: false,
      });
    }
  }


  verifyOTPCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({
      recaptchaToken: recaptchaToken,
    });
    if (this.state.loading) {
      this.callForgetPassOTPAPI();
    }
    this.callForgetPassOTPAPI();

  }

  proceedOTPcheck = () => {
    if (!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length)) {
      return false;
    }
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  callForgetPassOTPAPI = () => {
    let OTP = "" + this.state.firstinp + this.state.secondinp + this.state.thirdinp + this.state.fourthinp;
    AuthService.verifyForgetOTP(this.state.register_id, OTP, this.state.recaptchaToken).then(
      (data) => {
        if (data.response_code == 200) {
          // toast.success(data.message);
          this.setState({
            progress: 3,
            reset_pwd_token: data.result.reset_token,
            loading: false,
            otpPage: false,
            newPwdPage: true
          });
        } else {
          toast.error(data.message);
          this.setState({
            loading: false,
            invalidOTP: true
          });
        }
      });
  }

  resendOTP = () => {
    this.setState({
      resetTimer: false,
      disabled_resend_otp: true
    });
    AuthService.resendForgetOTP(this.state.register_id).then(
      (data) => {
        if (data.response_code == 200) {
          toast.success(data.message);
          this.setState({
            resetTimerTime: 30,
            resetTimer: true
          });
          setTimeout(() => { this.setState({ disabled_resend_otp: false }) }, data.result.resend_after * 1000);
        } else {
          toast.error(data.message);
          this.setState({
            loading: false,
            invalidOTP: true,
            resetTimerTime: 30,
            resetTimer: true
          });
        }
      });
  }



  handleResetPwd(e) {
    e.preventDefault();

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        pwdnotmatch: true,
      });
      return false;
    } else {
      this.setState({
        pwdnotmatch: false,
      });
    }

    this.setState({
      loading: true,
    });

    this.form.validateAll();
    const { dispatch, history } = this.props;
    if (this.checkRPwdBtn.context._errors.length === 0) {
      console.log(this.state.reset_pwd_token, this.state.password, this.state.confirmPassword);
      AuthService.resetPassword(this.state.reset_pwd_token, this.state.password, this.state.confirmPassword).then(
        (data) => {
          if (data.response_code == 200) {
            // history.push("/profile");
            this.setState({
              // register_id: data.result.id,
              // loading: false,
              // fpPage:false,
              // otpPage:true
              completePage: true,
              newPwdPage: false,
              progress: 4

            }, () => {
              this.countDown()
            });
            // toast.success(data.message);
          } else if (data.response_code == 429) {
            toast.error(data.message);
          } else {
            toast.error(data.message);
            this.setState({
              loading: false,
              reason: data.reason
            });
          }
        });

    } else {
      this.setState({
        loading: false,
      });
    }
  }

  tick = () => {
    if (this.state.timer > 0) {
      this.setState({ timer: this.state.timer - 1 }, () => {
        this.countDown()
      })
    } else {
      this.props.history.push("/home");
    }
  }

  countDown = () => {
    setTimeout(this.tick, 1000);
  }

  render() {
    const { message } = this.props;

    return (<>
      <div className="container-fluid login-container">
        <div className="row no-gutters">

          {/* login-logo */}
          <div className="col-lg-6 col-md-6">
            <div className="login-logo">
              <img src={logo} className="img-fluid onboarding-logo" alt="logo" />
            </div>
          </div>
          {/* End login-logo */}

          {/* Form */}
          <div className="col-lg-6 col-md-6">
            <div className="onboarding-form">
              <div className="onboarding-form-width">

                <ul className="process_step_bar progres-li-sm mb-3">
                  <li className={this.state.progress === 1 ? "dot_process" : this.state.progress >= 2 ? "active_process" : null}>Forgot Password</li>
                  <li className={this.state.progress === 2 ? "dot_process" : this.state.progress >= 3 ? "active_process" : null}>OTP Verification</li>
                  <li className={this.state.progress === 3 ? "dot_process" : this.state.progress >= 4 ? "active_process" : null}>Create New Password</li>
                  <li className={this.state.progress === 4 ? "active_process" : null}>Complete</li>
                </ul>

                {/* Forgot Password */}
                {this.state.fpPage &&
                  <>
                    <h3 className="login-title">Forgot Password</h3>

                    <Form onSubmit={this.handleForgetPwd} ref={(c) => { this.form = c; }}>
                      <div className="form-group">
                        <lable className="lable-input">Account Code / Account Email ID<span className="red-star">*</span></lable>
                        <Input
                          type="text"
                          className={"form-control " + (this.state.incorrect_email ? "wrong-red" : "")}
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                          validations={[emailAccountCode]}
                        />
                        {/* {this.state.reason.account_id != undefined && <small className="form-error-text">{this.state.reason.account_id}</small>} */}
                      </div>

                      <div className="form-group">
                        <lable className="lable-input">Username<span className="red-star">*</span></lable>
                        <Input
                          type="text"
                          className={"form-control " + (this.state.incorrect_uname ? "wrong-red" : "")}
                          name="username"
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                          validations={[vusername]}
                        />
                        {/* {this.state.reason.username != undefined && <small className="form-error-text">{this.state.reason.username}</small>} */}
                      </div>

                      <button type="submit" className={"btn btn-success btn-block apply_btn_new position-relative mt-4" + (this.state.loading ? "" : "")} disabled={this.state.loading}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}Send OTP
                      </button>

                      <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={RECAPTCHA_SITE_KEY}
                        action='action_name'
                        verifyCallback={this.verifyCallback}
                      />

                      <div className="login-p-text">
                        <p>Back to <Link className="s-sbold blue-text" to="/login">Login</Link></p>
                      </div>

                      <CheckButton
                        style={{ display: "none" }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />
                    </Form>
                  </>
                }
                {/* End - Forgot Password */}

                {/* OTP form */}
                {this.state.otpPage &&
                  <>
                    <h3 className="login-title">OTP Verification</h3>
                    <lable className="lable-input">Enter OTP Code sent to <span className="s-sbold blue-text">{this.state.email}</span></lable>

                    <form>
                      <div className="form-group">
                        <div className="otp-reg mt-1 mb-1">
                          <input type="text" maxLength="1"
                            value={this.state.firstinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeFirstinp}
                            ref={(ip) => this.firstinp = ip}
                            // className={"form-control otp-input-text " + (this.state.invalidOTP ? "red" : "")} 
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                          <input type="text" maxLength="1"
                            value={this.state.secondinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeSecondinp}
                            ref={(ip) => this.secondinp = ip}
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                          <input type="text" maxLength="1"
                            value={this.state.thirdinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeThirdinp}
                            ref={(ip) => this.thirdinp = ip}
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                          <input type="text" maxLength="1"
                            value={this.state.fourthinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeFourthinp}
                            ref={(ip) => this.fourthinp = ip}
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                        </div>

                        <div className="otp-reg mb-1">
                          {this.state.invalidOTP && <small className="form-error-text w-100">The entered OTP is incorrect</small>}
                        </div>
                      </div>


                      <div className="login-p-text">
                        <p>Expire in {this.state.resetTimer ? <Timer initialMinute={this.state.resetTimerTime} /> : `00:00`}</p>

                        <p className="resend-otp-reg">
                          <span>Didn't receive OTP code ?</span>
                          <span className={this.state.disabled_resend_otp ? "resend-otp-text resend-otp-disabled" : "resend-otp-text"} onClick={() => { this.state.disabled_resend_otp ? "" : this.resendOTP() }}>Resend OTP</span>
                        </p>
                      </div>

                      <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={RECAPTCHA_SITE_KEY}
                        action='action_name'
                        verifyCallback={this.verifyOTPCallback}
                      />

                      {/* <button type="button" onClick={this.proceedOTPcheck} className={"btn btn-primary user-form-btton position-reltv" + (!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length) ? "btn-bg-gray" : "btn-bg-gray") + (this.state.loading ? " position-reltv" : "")}>
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Proceed</span>
                      </button> */}

                      <button type="button" onClick={this.proceedOTPcheck} className={"btn btn-success btn-block apply_btn_new mt-4 position-relative " + (!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length) ? "gray_btn_new" : "") + (this.state.loading ? " position-relative" : "")}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}Proceed
                      </button>

                    </form>
                  </>
                }
                {/* End - OTP form */}

                {/* Create New Password */}
                {this.state.newPwdPage &&
                  <>
                    <h3 className="login-title">Create New Password</h3>

                    <Form
                      onSubmit={this.handleResetPwd}
                      ref={(c) => {
                        this.form = c;
                      }}
                    >
                      <div className="form-group">
                        <lable className="lable-input">Password<span className="red-star">*</span></lable>
                        <Input
                          type="password"
                          className="form-control"
                          name="password"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          validations={[required, vpassword]}
                        />
                      </div>

                      <div className="form-group">
                        <lable className="lable-input">Confirm Password<span className="red-star">*</span></lable>
                        <Input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          onChange={this.onChangeConfirmPassword}
                          validations={[required]}
                        />
                        {this.state.pwdnotmatch && <small className="form-error-text">Password does not match</small>}
                      </div>

                      {/* <button type="submit" className={"btn btn-primary user-form-btton position-reltv" + (this.state.loading ? "" : "")} disabled={this.state.loading}>
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Reset Password</span>
                      </button> */}

                      <button type="submit" className={"btn btn-success btn-block apply_btn_new mt-4 position-relative " + (this.state.loading ? "" : "")} disabled={this.state.loading}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}Reset Password
                      </button>

                      <CheckButton
                        style={{ display: "none" }}
                        ref={(c) => {
                          this.checkRPwdBtn = c;
                        }}
                      />
                    </Form>
                  </>
                }
                {/* End - Create New Password */}

                {/* Complete */}
                {this.state.completePage ?
                  <div className="complet-form">
                    <span className="icon-check-mark-1 done-all-icon"></span>
                    <h3>All done!</h3>
                    <p className="mt-3 mb-0">You will be re directed to <span className="s-sbold blue-text">login in {this.state.timer} seconds</span></p>
                    <p>if not redirected please <a className="s-sbold blue-text" onClick={() => this.redirectHome()} href="">click here</a></p>
                  </div>
                  : null
                }
                {/* End - Complete */}
              </div>
            </div>
          </div>
          {/* End Form */}
        </div>
      </div>
    </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);