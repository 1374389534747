import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import logo from '../../Assets/img/logo.png';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { login } from "../../Redux/Actions/Auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../../Services/AuthService";
import { setAppilcationLoder } from "../../Redux/Actions/App";

import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../Utils/Config';
import SessionManagement from "../../Utils/SessionManagement";

import { Button } from 'react-bootstrap';

const required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Password is required</small>
    );
  }
};

const cprequired = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Confirm password is required</small>
    );
  }
};


const emailAccountCode = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Account Code / Account Email ID is required</small>
    );
  }
  let isnum = /^\d+$/.test(value);
  if (!isEmail(value) && !isnum) {
    return (
      <small className="form-error-text">Please enter valid Account Code / Email ID</small>
    );
  } else if (value.length != 8 && isnum) {
    return (
      <small className="form-error-text">Please enter valid Account Code / Account Code</small>
    );
  }
};

const vusername = (value) => {
  let is_uname = /^[a-zA-Z0-9]{6,20}$/.test(value);
  if (!value) {
    return (
      <small className="form-error-text">Username is required</small>
    );
  } else if (value.length < 6 || value.length > 20 || !is_uname) {
    return (
      <small className="form-error-text">Username should be 6-20 alphanumeric characters.</small>
    );
  }
};
const vpassword = (value) => {
  let is_valid_pwd = /^[a-zA-Z0-9_ !@#*$&()\\-`.+,/\"]{6,20}$/.test(value);
  if (value.length < 6 || value.length > 20 || !is_valid_pwd) {
    return (
      <small className="form-error-text">Password should be 6-20 alphanumeric {'&'} special characters.</small>
    );
  }
};


class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmailId = this.onChangeEmailId.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      emailId: "",
      username: "",
      password: "",
      confirm_password: "",
      is_emailid: false,
      is_password: false,
      is_username: false,
      email_changed: false,
      username_changed: false,
      password_changed: false,
      remeberMe: true,
      loading: false,
      token: !!this.props.match.params.token ? this.props.match.params.token : "",
      errors: {},
    };
  }

  onChangeEmailId(e) {
    this.setState({
      emailId: e.target.value,
    });
    if (Object.keys(this.state.errors).length > 0) {
      this.setState({
        email_changed: true
      })
    } else {
      this.setState({ email_changed: false })
    }
    // red error on input field
    if (emailAccountCode(e.target.value) === undefined) {
      this.setState({
        is_emailid: false,
      });
    } else {
      this.setState({
        is_emailid: true,
      });
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
    if (Object.keys(this.state.errors).length > 0) {
      this.setState({
        username_changed: true
      })
    } else {
      this.setState({ username_changed: false })
    }
    // red error on input field
    if (vusername(e.target.value) === undefined) {
      this.setState({
        is_username: false,
      });
    } else {
      this.setState({
        is_username: true,
      });
    }
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
    if (Object.keys(this.state.errors).length > 0) {
      this.setState({
        password_changed: true
      })
    } else {
      this.setState({ password_changed: false })
    }
    // red error on input field
    if (required(e.target.value) === undefined) {
      this.setState({
        is_password: false,
      });
    } else {
      this.setState({
        is_password: true,
      });
    }
  }

  onChangeConfirmPassword = (e) => {
    let value = e.target.value;
    this.setState({
      confirm_password: value,
    });
    let errors = this.state.errors;
    if (value == "") {
      errors.confirm_password = "Confirm password is required.";
    } else if (value != this.state.password) {
      errors.confirm_password = "Password does not match.";
    } else {
      errors.confirm_password = "";
    }
    this.setState({ errors })
  }

  getUserDetail = () => {
    this.props.dispatch(setAppilcationLoder(true));
    AuthService.invite(this.state.token).then(
      (data) => {
        this.props.dispatch(setAppilcationLoder(false));
        if (data.response_code == 200) {
          this.setState({
            emailId: data.result.account_id,
            username: data.result.username
          })
        } else {
          toast.error("Invalid url");
          this.setState({ token: "" })
          this.props.history.push("/error-page")
        }
      });
  }

  componentDidMount() {
    loadReCaptcha(RECAPTCHA_SITE_KEY);
    if (this.state.token)
      this.getUserDetail();
  }

  handleLogin(e) {
    e.preventDefault();

    this.form.validateAll();

    if (this.state.emailId == "") {
      this.setState({
        is_emailid: true,
      });
    }
    if (this.state.password == "") {
      this.setState({
        is_password: true,
      });
    }
    if (this.state.username == "") {
      this.setState({
        is_username: true,
      });
    }

    if (!!this.state.token) {
      let errors = this.state.errors;
      if (this.state.confirm_password == "") {
        errors.confirm_password = "Confirm password is required.";
        return true;
      } else if (this.state.confirm_password != this.state.password) {
        errors.confirm_password = "Password does not match.";
        return true;
      }
      this.setState({ errors });
    }

    if (this.checkBtn.context._errors.length === 0) {

      this.setState({
        loading: true,
      });

      // you will get a new token in verifyCallback
      this.recaptcha.execute();
      //this.callLoginAPI();
    }
  }


  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({
      recaptchaToken: recaptchaToken,
    });
    if (this.state.loading) {
      this.callLoginAPI();
    }

  }

  callLoginAPI = () => {

    const { dispatch, history } = this.props;
    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.emailId, this.state.username, this.state.password, this.state.recaptchaToken || "", (this.state.remeberMe ? 1 : 0), this.state.token).then(
        (data) => {

          if (data.response_code == 200) {
            let user_detail = data.result;

            localStorage.setItem("user", JSON.stringify(user_detail.user_details));
            dispatch(login(user_detail));

            SessionManagement.setUserDetail(user_detail.user_details);
            SessionManagement.setRememberMe(this.state.remeberMe);
            SessionManagement.setAccessToken(user_detail.access_token);
            SessionManagement.setRefreshToken(user_detail.refresh_token);
            let last_url = SessionManagement.getLastUrl() || "/welcome";
            //history.push(last_url);
            window.location.href = window.location.origin + last_url;
            // window.location.reload();

            this.setState({
              loading: false
            });
          } else {
            toast.error(data.message);
            this.setState({
              loading: false,
              errors: { ...this.state.errors, isInvalid: true },
              email_changed: false,
              username_changed: false,
              password_changed: false,
            });
          }
        });

    } else {
      this.setState({
        loading: false,
      });
    }
  }
  handleChangeChkRmbrMe = (e) => {
    this.setState({
      remeberMe: e.target.checked,
    });
  }

  render() {
    const disabled_token = !!this.state.token;

    return (<>
      {/* <main className="login-wrapper"> */}
      <div className="container-fluid login-container">
        <div className="row no-gutters">

          {/* login-logo */}
          <div className="col-lg-6 col-md-6">
            <div className="login-logo">
              <img src={logo} className="img-fluid onboarding-logo" alt="logo" />
            </div>
          </div>
          {/* End login-logo */}

          {/* Form */}
          <div className="col-lg-6 col-md-6">
            <div className="onboarding-form">
              <div className="onboarding-form-width">
                <h3 className="login-title">{!!this.state.token ? "Create new Password" : "Login to Buktec"}</h3>

                <Form onSubmit={this.handleLogin} ref={(c) => { this.form = c; }} >
                  <div className="form-group">
                    <lable className="lable-input">{disabled_token ? "" : "Account Code / "}Account Email ID<span className="red-star">*</span></lable>
                    <Input
                      type="text"
                      className={"form-control " + (this.state.is_emailid ? "wrong-red" : "")}
                      name="emailId"
                      disabled={disabled_token}
                      value={this.state.emailId}
                      onChange={this.onChangeEmailId}
                      validations={[emailAccountCode]}
                    />
                    {/* {this.state.errors.isInvalid && !this.state.email_changed?<small className="form-error-text">Invalid Email</small>:null} */}
                    {/* <small className="form-error-text">Account code/ Account email is required</small> */}
                  </div>

                  <div className="form-group">
                    <lable className="lable-input">Username<span className="red-star">*</span></lable>
                    <Input
                      type="text"
                      className={"form-control " + (this.state.is_username ? "wrong-red" : "")}
                      name="username"
                      disabled={disabled_token}
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                      validations={[vusername]}
                    />
                    {/* {this.state.errors.isInvalid && !this.state.is_username?<small className="form-error-text">Invalid Username</small>:null} */}
                  </div>

                  <div className="form-group">
                    <lable className="lable-input">Password<span className="red-star">*</span></lable>
                    <Input
                      type="password"
                      className={"form-control " + (this.state.is_password ? "wrong-red" : "")}
                      name="password"
                      maxLength={20}
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                    />
                    {/* {this.state.errors.isInvalid && !this.state.is_password?<small className="form-error-text">Invalid Password</small>:null} */}
                    {/* <small className="form-error-text">Password is required</small> */}
                  </div>

                  {
                    !!this.state.token &&
                    <div className="form-group">
                      <lable className="lable-input">Confirm Password</lable>
                      <Input
                        type="password"
                        className={"form-control " + (this.state.errors.confirm_password ? " wrong-red " : "")}
                        name="password"
                        maxLength={20}
                        value={this.state.confirm_password}
                        onChange={this.onChangeConfirmPassword}
                      // validations={[cprequired, vpassword]}
                      />
                      {this.state.errors.confirm_password && <small className="form-error-text">{this.state.errors.confirm_password}</small>}
                    </div>
                  }

                  <div className="form-group">
                    <div className="sup-cust-check">
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-is_keep"
                        type="checkbox" onChange={this.handleChangeChkRmbrMe} checked={this.state.remeberMe}
                      /><label class="lable-input" for="styled-checkbox-is_keep">Keep me signed in</label>
                    </div>
                  </div>

                  <button className={"btn btn-success btn-block apply_btn_new position-relative " + (this.state.loading ? "" : "")} disabled={this.state.loading} >
                    {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}Continue
                  </button>

                  {/* <Button variant={"success btn-block apply_btn_new " + (this.state.loading ? "position-relative" : "")} disabled={this.state.loading} >
                    {this.state.loading && (<span className="spinner-border spinner-border-sm"></span>)}Continue
                  </Button> */}


                  <div className="login-p-text">
                    <p>By selecting Login, you agree to our <a href="https://thebuktec.com/new/terms-and-conditions/" target="_blank" className="blue-text s-sbold">Terms of Use</a> and have read and acknowledged our <a href="https://thebuktec.com/new/privacy-policy/" target="_blank" className="blue-text s-sbold"> Privacy Policy</a>.</p>

                    {!!!this.state.token &&
                      <p>Forgot <Link className="green-text s-sbold" to="/forgot-username">Username</Link>  or <Link className="green-text s-sbold" to="/forgot-password">Password</Link>?</p>
                    }
                    {!!!this.state.token &&
                      <p>Don't have an account yet? <Link to="/register" className="blue-text s-sbold">START FREE TRIAL</Link>  </p>
                    }

                    <ReCaptcha
                      ref={ref => this.recaptcha = ref}
                      sitekey={RECAPTCHA_SITE_KEY}
                      action='action_name'
                      verifyCallback={this.verifyCallback}
                    />
                    <p className="mb-0">Invisible reCAPTCHA by Google</p>

                    <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                        this.checkBtn = c;
                      }}
                    />
                  </div>
                </Form>

              </div>
            </div>
          </div>
          {/* End Form */}

        </div>
      </div>
      {/* </main> */}
    </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(Login); 